
import { defineComponent, ref, onMounted, computed } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import PromotionCodeService from "@/core/services/PromotionCodeService";
import PromotionCode from "@/models/IPromotionCode";
import { roles } from "@/store/stateless/store";

interface PromotionCodeBodyParam {
  per_page: string;
  expired: any;
}

export default defineComponent({
  name: "users-list",
  components: {},
  data() {
    return {
      data: [],
    };
  },
  setup() {
    // let customers_obj = ref([]);

    const store = useStore();
    let bodyParameters = {} as PromotionCodeBodyParam;
    let promotionCodeList = ref<PromotionCode>();

    bodyParameters = {
      per_page: "50",
      expired: 0,
    };

    onMounted(() => {
      if(!roles.promoCodes.includes(roles.userId())) window.location.href = '/#/404'
      
      
      MenuComponent.reinitialization();

      setCurrentPageBreadcrumbs("Promotion Codes", []);
      // API Action to get promo code list
      getPromotionCodeList(bodyParameters);
    });

    const getPromotionCodeList = (bodyParameters, index = 1) => {
      console.log("bodyParameters", bodyParameters);
      Promise.all([
        PromotionCodeService.getPromotionCodeList(bodyParameters, index),
      ]).then((data) => {
        promotionCodeList.value = store.getters.getPromotionCode;
        console.log("promotionCodeList", promotionCodeList.value);
      });
    };

    const onChangeStatus = (value) => {
      console.log("bodyParameters", bodyParameters);
      bodyParameters.expired = value;
      getPromotionCodeList(bodyParameters);
    };

    // let promotionCodeList = computed(() => {
    //   return store.getters.promotionCodeList;
    // });

    const getPaginationUrl = (index) => {
      let pagenationUrl = index.split("=");
      getPromotionCodeList(bodyParameters, pagenationUrl[1]);
    };
    const onPerPageChange = (event) => {
      bodyParameters.per_page = event.target.value;
      getPromotionCodeList(bodyParameters);
    };

    return {
      roles,
      bodyParameters,
      getPromotionCodeList,
      promotionCodeList,
      onChangeStatus,
      onPerPageChange,
      getPaginationUrl,
    };
  },
});
